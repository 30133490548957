<template>
  <div>
    <v-dialog
      v-model="dialogMessage" persistent width="500">
      <v-card>
        <p class="text-md-center"><v-icon style="font-size: 100px;" v-bind:style="{ 'color': iconColor }">{{iconText}}</v-icon></p>
        <v-card-title class="headline">
            <h1 class="headline mb-0"><b><p class="text-md-center">{{titleMessage}}</p></b></h1>
        </v-card-title>
        <v-card-text>
            <h3 class="headline mb-0"><b><p class="text-md-center">{{bodyMessage}}</p></b></h3>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="mb-2"
            @click="dialogMessage = false"
          >
          OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-btn color="primary" dark slot="activator" class="mb-2" v-on:click="printReportArrayBufferTest()">Print ByteArray</v-btn>
    <v-btn color="primary" dark slot="activator" class="mb-2" v-on:click="printReportBase64Test()">Print Base64</v-btn>
    <v-btn color="primary" dark slot="activator" class="mb-2" v-on:click="printTestIltemSample()">Print Test Item Sample</v-btn>
    <v-btn color="primary" dark slot="activator" class="mb-2" v-on:click="printResourceDatabase()">Print Resource DB</v-btn>
    <v-btn color="primary" dark slot="activator" class="mb-2" v-on:click="printResourceProcDatabase()">Print Resource Proc DB</v-btn>
  </div>
</template>
<script>
import axios from 'axios'
import Base64Binary from 'base64-arraybuffer'
export default {
  data: () => ({
    iconColor: '',
    iconText: 'message',
    dialogMessage: false,
    titleMessage: '',
    bodyMessage: '',
  }),
  methods: {
    printReportArrayBufferTest () {
      axios.post(process.env.VUE_APP_API + '/test/getReportArrayBuffer', {}, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/pdf'
        }
      })
        .then(response => {
          const blob = new Blob([response.data], {type: 'application/pdf; charset=utf-8'})
          const url = window.URL.createObjectURL(blob)
          window.open(url)
        })
        .catch(e => {
          this.$swal({type: 'error', title: e.message})
        })
    },
    printReportBase64Test () {
      // this.$http.post(process.env.VUE_APP_API + '/getReportTest',  {
      //   user_id: 'aaa',
      //   password: 'bbb' } , {responseType: 'arraybuffer'}).then(function (response) {
      //     // Success
      //   alert(response.data)
      //   const blob = new Blob([response.data], {type: 'application/pdf'})
      //   const url = window.URL.createObjectURL(blob)
      //   window.open(url)
      // }, function (response) {
      //     // Error
      //   alert(response.data)
      // })

      axios.post(process.env.VUE_APP_API + '/test/getReportBase64', {}, {})
        .then(response => {
          if (response.data.isSuccess) {
            // const blob = new Blob([Base64Binary.decode(response.data.pdf_base64)], {type: 'application/pdf; charset=utf-8'})
            // const url = window.URL.createObjectURL(blob)
            // window.open(url)
            const url = window.URL.createObjectURL(new Blob([Base64Binary.decode(response.data.pdf_base64)], {type: 'application/pdf; charset=utf-8'}))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'file.pdf')
            document.body.appendChild(link)
            link.click()
          } else {
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(e => {
          this.$swal({type: 'error', title: e.message})
        })
    },
    printReportUserInfo (item) {
      var dataJSON = []
      var data = []
      data.push({ 'user_id': item.user_id, 'first_name': item.first_name, 'last_name': item.last_name, 'employee_no': item.employee_no == null ? '' : item.employee_no, 'facility_cd': item.facility_cd == null ? '' : item.facility_cd, 'picture_base64': item.picture_base64 == null ? 'iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=' : item.picture_base64 })
      dataJSON.push({'data': data})
      // this.dataJSON.data = []
      // this.dataJSON.data.push({ 'user_id': item.user_id, 'first_name': item.first_name, 'last_name': item.last_name, 'employee_no': item.employee_no == null ? '' : item.employee_no, 'facility_cd': item.facility_cd == null ? '' : item.facility_cd, 'picture_base64': item.picture_base64 == null ? 'iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=' : item.picture_base64 })
      axios.post(process.env.VUE_APP_API + '/getReportByJSONBase64', {
        report_name: 'user_info',
        data_json: dataJSON}, {})
        .then(response => {
          if (response.data.isSuccess) {
            const blob = new Blob([Base64Binary.decode(response.data.pdf_base64)], {type: 'application/pdf; charset=utf-8'})
            const url = window.URL.createObjectURL(blob)
            window.open(url)
          } else {
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(e => {
          this.$swal({type: 'error', title: e.message})
        })
    },
    printTestIltemSample () {
      var dataJSON = []
      var data = []
      data.push({'test_item_id': 'A001', 'test_item_desc': 'A001 Desescription', 'description': 'Desescription 1'})
      data.push({'test_item_id': 'A002', 'test_item_desc': 'A002 Desescription', 'description': 'Desescription 2'})
      data.push({'test_item_id': 'A003', 'test_item_desc': 'A003 Desescription', 'description': 'Desescription 3'})
      data.push({'test_item_id': 'A004', 'test_item_desc': 'A004 Desescription', 'description': 'Desescription 4'})
      data.push({'test_item_id': 'A005', 'test_item_desc': 'A005 Desescription', 'description': 'Desescription 5'})
      data.push({'test_item_id': 'A006', 'test_item_desc': 'A006 Desescription', 'description': 'Desescription 6'})
      data.push({'test_item_id': 'A007', 'test_item_desc': 'A007 Desescription', 'description': 'Desescription 7'})
      dataJSON.push({'data': data})

      axios.post(process.env.VUE_APP_API + '/getReportByJSONBase64', {
        report_name: 'test_item_sample',
        data_json: dataJSON}, {})
        .then(response => {
          if (response.data.isSuccess) {
            const blob = new Blob([Base64Binary.decode(response.data.pdf_base64)], {type: 'application/pdf; charset=utf-8'})
            const url = window.URL.createObjectURL(blob)
            window.open(url)
          } else {
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(e => {
          this.$swal({type: 'error', title: e.message})
        })
    },
    printResourceDatabase () {
      var param = {}
      param = {'uom': 'N/A'}

      axios.post(process.env.VUE_APP_API + '/getReportDatabaseMSSQL', {
        report_name: 'resource_database',
        param: param}, {})
        .then(response => {
          if (response.data.isSuccess) {
            const blob = new Blob([Base64Binary.decode(response.data.pdf_base64)], {type: 'application/pdf; charset=utf-8'})
            const url = window.URL.createObjectURL(blob)
            window.open(url)
          } else {
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(e => {
          this.$swal({type: 'error', title: e.message})
        })
    },
    printResourceProcDatabase () {
      var param = {}
      param = {'uom': 'EA', 'resource_no': '002'}

      axios.post(process.env.VUE_APP_API + '/getReportDatabaseMSSQL', {
        report_name: 'resource_proc_database',
        param: param}, {})
        .then(response => {
          if (response.data.isSuccess) {
            const blob = new Blob([Base64Binary.decode(response.data.pdf_base64)], {type: 'application/pdf; charset=utf-8'})
            const url = window.URL.createObjectURL(blob)
            window.open(url)
          } else {
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(e => {
          this.$swal({type: 'error', title: e.message})
        })
    }
  }
}
</script>
